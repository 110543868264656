<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogFormVisible" title="列表" class="button_bg" width="60%" >
    <el-table :data="tableData" style="width: 100%" border>
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column prop="beginAt" label="开始时间" min-width="150">
        <template #default="scope">
          {{ scope.row.beginAt }}
        </template>
      </el-table-column>
      <el-table-column prop="endAt" label="结束时间" min-width="150">
        <template #default="scope">
          {{ scope.row.endAt }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="districtName" label="提示" min-width="250">
          <template #default="scope">
            <!-- <el-tooltip class="item" effect="dark"  placement="top-start">
              <template #content>{{scope.row.errLog}} <br> {{scope.row.bussinessLog}} </template>
              <span v-if="scope.row.errLog + scope.row.bussinessLog" size="small" class="sbcg" style="border: 0;"> {{scope.row.errLog}} <br> {{scope.row.bussinessLog}} </span>
              <span v-else-if="scope.row.endAt && scope.row.taskStatus == 3 && scope.row.bussinessStatus == 2">成功</span>
            </el-tooltip> -->
            <!-- <el-tooltip class="item" effect="dark" :content="scope.row.errLog + scope.row.bussinessLog" placement="top-start"> -->
              <span v-if="scope.row.errLog + scope.row.bussinessLog" size="small" class="sbyc2" style="border: 0;"> {{scope.row.errLog}} <br> {{scope.row.bussinessLog}} </span>
              <span v-else-if="scope.row.endAt && scope.row.taskStatus == 3 && scope.row.bussinessStatus == 2" class="sbcg2">成功</span>
            <!-- </el-tooltip> -->
          </template>
        </el-table-column>
      <!-- <el-table-column prop="errLog" label="提示信息" min-width="10px">
        <template #default="scope">
          <el-tooltip class="item" effect="dark" :content="scope.row.errLog" placement="top-start">
            <el-button class="tsxx">{{ scope.row.errLog }}</el-button>
          </el-tooltip>
        </template>
      </el-table-column> -->
      <el-table-column v-if="image" prop="image" label="图片">
        <template #default="scope">
          {{ scope.row.image }}
        </template>
      </el-table-column>


      <!-- <el-table-column prop="taskStatus" label="状态" v-if="taskName == 'tax-sb-vat' ">
        <template #default="scope">
          <div class="item_icon">
            <p v-if="scope.row.taskStatus == '0'"><i class="iconfont icon-gantanhao"></i>{{$setUpStatusSb(scope.row.taskStatus)}}</p>
            <p v-if="scope.row.taskStatus == '1'"><i class="iconfont icon-info"></i>{{$setUpStatusSb(scope.row.taskStatus)}}</p>
            <p v-if="scope.row.taskStatus == '2'"><i class="iconfont icon-info"></i>{{$setUpStatusSb(scope.row.taskStatus)}}</p>
            <p v-if="scope.row.taskStatus == '3'"><i class="iconfont icon-duihao"></i>{{$setUpStatusSb(scope.row.taskStatus)}}</p>
            <p v-if="scope.row.taskStatus == '4'"><i class="iconfont icon-duihao"></i>{{$setUpStatusSb(scope.row.taskStatus)}}</p>
            <p v-if="scope.row.taskStatus == '5'"><i class="iconfont icon-info"></i>{{$setUpStatusSb(scope.row.taskStatus)}}</p>
            <p v-if="scope.row.taskStatus == '6'"><i class="iconfont icon-duihao"></i>{{$setUpStatusSb(scope.row.taskStatus)}}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="taskStatus" label="状态" v-if="taskName == 'tax-kk-vat' ">
        <template #default="scope">
          <div class="item_icon">
            <p v-if="scope.row.taskStatus == '0'"><i class="iconfont icon-gantanhao"></i> {{$setUpStatusKk(scope.row.taskStatus)}}</p>
            <p v-if="scope.row.taskStatus == '1'"><i class="iconfont icon-info"></i>{{$setUpStatusKk(scope.row.taskStatus)}}</p>
            <p v-if="scope.row.taskStatus == '2'"><i class="iconfont icon-info"></i>{{$setUpStatusKk(scope.row.taskStatus)}}</p>
            <p v-if="scope.row.taskStatus == '3'"><i class="iconfont icon-duihao"></i>{{$setUpStatusKk(scope.row.taskStatus)}}</p>
            <p v-if="scope.row.taskStatus == '4'"><i class="iconfont icon-info"></i>{{$setUpStatusKk(scope.row.taskStatus)}}</p>
            <p v-if="scope.row.taskStatus == '5'"><i class="iconfont icon-duihao"></i>{{$setUpStatusKk(scope.row.taskStatus)}}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="taskStatus" label="状态" v-if="taskName == 'tax-jc-shenbao' ">
        <template #default="scope">
          <div class="item_icon">
            <p v-if="scope.row.taskStatus == '0'"><i class="iconfont icon-gantanhao"></i> {{$setUpStatusJc(scope.row.taskStatus)}}</p>
            <p v-if="scope.row.taskStatus == '1'"><i class="iconfont icon-info"></i>{{$setUpStatusJc(scope.row.taskStatus)}}</p>
            <p v-if="scope.row.taskStatus == '2'"><i class="iconfont icon-info"></i>{{$setUpStatusJc(scope.row.taskStatus)}}</p>
            <p v-if="scope.row.taskStatus == '3'"><i class="iconfont icon-duihao"></i>{{$setUpStatusJc(scope.row.taskStatus)}}</p>
            <p v-if="scope.row.taskStatus == '4'"><i class="iconfont icon-info"></i>{{$setUpStatusJc(scope.row.taskStatus)}}</p>
          </div>
        </template>
      </el-table-column> -->


    </el-table>
    <div class="dialog-footer">
      <el-button @click="dialogFormVisible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dialogFormVisible = false" size="small">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { taxTaskList } from "@/api/taxCalculation"
export default {
  name:'declareDetails',
  props: {
    
  },
  data() {
    return{
      dialogFormVisible:false,
      tableData: [],
      contentStyleObj:{}, //高度变化
      taskName: ''
    } 
  },
  methods: {
    //弹窗
    init(comId, period, taskName){
      if(!comId || !period){
        this.$qzfMessage("信息错误", 1)
        return
      }
      if(!taskName) {
        this.$qzfMessage("无记录", 1)
        return
      }
      
      taxTaskList({taskName, comId, period}).then(res=>{
        if(res.data.data.list && res.data.data.list.length != 0){
          this.tableData = res.data.data.list
          this.taskName = taskName
          this.dialogFormVisible = true
        }else{
          this.$qzfMessage("无记录", 1)
          return
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.dialog-footer {
  text-align: right;
  margin-top: 10px;
}
.item_icon{
  display: inline-block;
  cursor: pointer;
  i{
    display: inline-block;
    line-height: 28px;
    font-size: 16px;
  }
  .iconfont icon-cuowu{
    color: #f56c6c;
  }
  .iconfont icon-duihao{
    color: #67c23a;
  }
  .el-icon-circle-check{
    color: #67c23a;
  }
  .iconfont icon-info{
    color: #409eff
  }
  .iconfont icon-cuowu{
    color: #e6a23c;
  }
  .iconfont icon-cuowu-outline{
    color: #e6a23c;
  }

  p{
    display: inline-block;
    font-size: 14px;
    line-height: 28px;
    color: #333;
  }
}
</style>