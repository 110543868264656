<template>
  <div class="app-container">
   <!-- todo -->
    <div class="top_btns">
      <div class="left_box">
        <!-- <el-date-picker @change="getList" :clearable="false" v-model="listQuery.period" type="month" value-format="YYYYMM" size="small" placeholder="日期" style="width: 160px;margin-right:5px;"></el-date-picker> -->
        <el-input placeholder="请输入企业名称" v-model="listQuery.name" style="width: 160px;" class="filter-item" size="small" />
        <el-button class="filter-item1" type="primary" size="small" plain @click="getList">搜索</el-button>
        <search @success="getList">
          <el-form style="margin:11px 0px 0px 6px;width:600px" class="styleForm">
            <el-form-item label="采集状态：" :label-width="formLabelWidth">
              <selectCjJzStatus v-model:allStatus="listQuery.allStatus"></selectCjJzStatus>

              <!-- <el-checkbox-group size="small" v-model="listQuery.taskStatus">
                <el-checkbox-button v-for="item in options" :key="item.value" :label="item.value">{{item.label}}</el-checkbox-button>
              </el-checkbox-group> -->
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div class="right_box">
        <el-button size="small" type="primary" @click="declaration()">批量采集</el-button>
        <el-dropdown @command="handleCommand" size="small" style="margin-left:10px">
          <el-button size="small" type="primary">
            批量补充<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu style="text-align:center">
              <el-dropdown-item command="a">
                <span>存货</span>
              </el-dropdown-item>
              <el-dropdown-item command="b">
                <span>资产</span>
              </el-dropdown-item>
              <el-dropdown-item command="d">
                <span>员工</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <div class="none-border">
      <el-table stripe :data="list" :height="contentStyleObj" border @selection-change="handleSelectionChange" @filter-change="tableFilterChange">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" prop="id" type="selection" min-width="55" />
        <el-table-column align="center" type="index" label="编号" min-width="50" />
        <el-table-column align="left" prop="name" label="公司名称" min-width="180" class="gsmc">
          <template #default="scope">
            <span v-if="scope.row.type == '1'" class="xgmStyle">小</span>
            <span v-if="scope.row.type == '2'" class="ybStyle">般</span>
            {{ scope.row.name }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="districtName" label="采集状态" min-width="180">
          <template #default="scope">
            <div class="div_p">
              <div class="item_icon">
                <p>
                  <i :class="$setUpStatusJzCjIcon(scope.row.taskStatus,scope.row.bussinessStatus)"></i>
                  <el-tooltip v-if="scope.row.bussinessLog" class="scope.row" effect="dark" :content="scope.row.businessLog?scope.row.businessLog:'' + scope.row.errLog?scope.row.errLog:''" placement="top-start">
                    <el-button size="small" class="sbcg" style="border: 0;">{{$setUpStatusJzCj(scope.row.taskStatus, scope.row.bussinessStatus)}}</el-button>
                  </el-tooltip>
                  <span v-else>
                    <el-button size="small" class="sbcg" style="border: 0;">{{$setUpStatusJzCj(scope.row.taskStatus, scope.row.bussinessStatus)}}</el-button>
                  </span>
                </p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="manager" label="操作" min-width="180">
          <template #default="scope">
            <div class="div_p">
              <!-- <el-button @click="handleUpdate(scope.row)" size="small" type="text">采集</el-button> -->
              <el-button @click="declareDetail(scope.row)" size="small" type="text">采集记录</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
    </div>
  </div>
  <!-- 批量采集弹窗 -->
  <el-dialog :close-on-click-modal="false"
    v-model="dialogVisible"
    title="批量采集"
    width="30%"
    destroy-on-close>
    <el-form :rules="rules" ref="ruleForm" :model="form">
      <el-form-item label="软件名称" :label-width="formLabelWidth" style="margin-bottom:20px">
        <el-select v-model="form.name" placeholder="请选择软件" size="small" style="margin-left:0px">
          <!-- <el-option label="金财互联" value="金财互联"></el-option> -->
          <el-option label="账无忧" value="账无忧"></el-option>
          <el-option label="云代账" value="云代账"></el-option>
          <el-option label="亿企代账" value="亿企代账"></el-option>
          <el-option label="云帐房" value="云帐房"></el-option>
          <el-option label="浪潮云" value="浪潮云"></el-option>
          <el-option label="柠檬云" value="柠檬云"></el-option>
          <el-option label="柠檬云免费版" value="柠檬云免费版"></el-option>

          <el-option label="精斗云" value="精斗云"></el-option>
          <el-option label="易代账" value="易代账"></el-option>
          <el-option label="诺诺云" value="诺诺云"></el-option>
          <el-option label="快合财税" value="快合财税"></el-option>
          <el-option label="木牛盒子" value="木牛盒子"></el-option>
          <el-option label="代账魔方" value="代账魔方"></el-option>
          <el-option label="唯易" value="唯易"></el-option>
          <el-option label="账务云" value="账务云"></el-option>
          <el-option label="好会计" value="好会计"></el-option>
          <el-option label="快账" value="快账"></el-option>



        </el-select>
      </el-form-item>

      <el-form-item prop="userName" label="用户名" :label-width="formLabelWidth" style="margin-bottom:20px">
        <el-input style="width:200px" v-model="form.userName" size="small"></el-input>
      </el-form-item>

      <el-form-item prop="password" label="密码" :label-width="formLabelWidth" style="margin-bottom:20px">
        <el-input style="width:200px" v-model="form.password" size="small"></el-input>
      </el-form-item>

      <el-form-item v-if="form.name == '云代账' || form.name == '代账魔方'||  form.name == '账务云'" prop="orgCode" label="公司代码" :label-width="formLabelWidth" style="margin-bottom:20px">
        <el-input style="width:200px" v-model="form.orgCode" size="small"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" size="small">确定</el-button>
      </span>
    </template>
  </el-dialog>

  <declareDetails ref="declareDetails"></declareDetails>
</template>

<script>
import { companyHisList } from "@/api/company"
import { supplementInventory } from "@/api/build"
import declareDetails from "../batch/components/declareDetails.vue";
import selectCjJzStatus from "./components/selectCjJzStatus.vue"
import { currentAccountPeriod } from '@/utils'
import { sendTask } from "@/api/collection"
export default {
  name: "batchSendSb",
  components:{
    declareDetails,
    selectCjJzStatus
  },
  data(){
    return {
      activeName:'税款申报',
      formLabelWidth: '120px',
      listQuery: {
        page: 1,
        limit: 20,
        taskName:"other-cj-jianzhang",
        period: currentAccountPeriod(),
        name: '',
        taskStatus: [],
        allStatus:[],
        taxNames:[],
      },
      total:0,
      list:[],
      form: {
        name: '账无忧',
        userName:'',
        password:'',
        orgCode:''
      },
      rules: {
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
        userName: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        orgCode: [
          { required: true, message: '公司代码', trigger: 'blur' },
        ],
      },
      gsMainIds: undefined,
      cjzt: undefined,
      dialogVisible: false,
      options: [
        {
          value: 0,
          label: '未采集',
        },
        {
          value: 1,
          label: '排队中',
        },
        {
          value: 2,
          label: '采集中',
        },
        {
          value: 3,
          label: '采集完成',
        },
        {
          value: 4,
          label: '采集失败',
        },
      ],
      categories: [
        {text: '利润表', value: 'kj_lrb'}, 
        {text: '资产负债表', value: 'kj_zcfz'}, 
        {text: '增值税及附加税一般纳税人申报表', value: 'gs_vat'}, 
        {text: '小规模增值税纳税及附加税申报表', value: 'gs_small_vat'},
        {text: '财产和行为税纳税申报表', value: 'gs_deed'},
        {text: '企业所得税申报表', value: 'gs_tax_quarter'},
        {text: '综合所得个人所得税预扣预缴申报表', value: 'gs_gs'},
        {text: '附加税', value: 'gs_fjs'},
        {text: '文化事业建设费申报表', value: 'gs_whsyjsfsbbygz'},
        {text: '印花税纳税申报表', value: 'gs_stamp_duty_item'},
        {text: '残疾人就业保障金申报表', value: 'gs_cbj'},
        {text: '工会经费', value: 'gs_ghjf'},
        {text: '房产税土地税', value: 'gs_fcs'},
        {text: '非税收入', value: 'gs_fssr'},
        {text: '印花税纳税申报表', value: 'gs_stamp_duty_item_cb'},
        {text: '印花税纳税申报表', value: 'gs_stamp_duty_item_jb'},
        {text: '水利建设专项收入', value: 'gs_sl'},
        {text: '城市生活垃圾处置费', value: 'gs_ljcl'},
        {text: '工会经费', value: 'gs_qtsr'},
        {text: '个人生产经营所得(查账征收)', value: 'gs_gs_one'},
        {text: '现金流量表', value: 'kj_xjllb'},
        {text: '单位社会保险费日常申报(全责)', value: 'gs_sb'},
      ],
    }
  },
  created(){
    this.getList()
    this.contentStyleObj= this.$getHeight(240)
  },
  methods:{
    getList(){
      companyHisList(this.listQuery).then(res=>{
        console.log(res.data.data,'res.data.data')
        this.list = res.data.data.list
        this.total = res.data.data.total
      })
    },
    handleClick() {
      if(this.activeName == "税款申报") {
        this.listQuery.accountingType = 2
        this.getList()
      }if(this.activeName == "零申报") {
        this.listQuery.accountingType = 1
        this.getList()
      }
    },
    tableFilterChange(filters) {
      const values=Object.values(filters)
      const value=values[0]
      this.listQuery.taxNames=value
      this.getList()
    },
    handleSelectionChange(row) {
      this.gsMainIds = []
      this.cjzt = []
      row.map(info=>{
        this.gsMainIds.push(info.comId)
      })
      row.map(info=>{
        this.cjzt.push(info.taskStatus)
      })
    },
    //批量补充
    handleCommand(command) {
      let state = true
      this.cjzt.map(item => {
        if(item != 3) {
          state = false
        }
      })
      if(command == 'a'){
        if(state == true) {
          this.$confirm('确定要批量补充存货吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            supplementInventory({type: 'inventory', comIds:this.gsMainIds}).then(res => {
              if(res.data.msg == 'success') {
                this.$message({ type: 'success',message: '成功批量补充存货!' });
              }
            })
          })
        }else {
          this.$message({ type: 'warning',message: '有企业未采集！' });
        }
      }else if(command == 'b'){
        if(state == true) {
          this.$confirm('确定要批量补充资产吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            supplementInventory({type: 'asset', comIds:this.gsMainIds}).then(res => {
              if(res.data.msg == 'success') {
                this.$message({ type: 'success',message: '成功批量补充资产!' });
              }
            })
          })
        }else {
          this.$message({ type: 'warning',message: '有企业未采集！' });
        }
      }else if(command == 'd'){
        if(state == true) {
          this.$confirm('确定要批量补充员工吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            supplementInventory({type: 'emp', comIds:this.gsMainIds}).then(res => {
              if(res.data.msg == 'success') {
                this.$message({ type: 'success',message: '成功批量补充员工!' });
              }
            })
          })
        }else {
          this.$message({ type: 'warning',message: '有企业未采集！' });
        }
      }
    },
    // 申报详情
    declareDetail(e) {
      this.$refs.declareDetails.init(e.comId,e.startAccountPeriod,e.taskName)
    },
    //批量申报
    declaration() {
      if(!this.gsMainIds){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      let data = localStorage.getItem("lssj")
      if(data){
        this.form = JSON.parse(data)
      }
      this.dialogVisible = true
    },
    //批量采集确定
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let cond = {
            // comId: v * 1,
            orgCode: this.form.orgCode,
            userName: this.form.userName,
            password: this.form.password,
            softwareName: this.form.name
          }
          this.dialogVisible = false
          //临时保存账号密码
          localStorage.setItem("lssj",JSON.stringify(this.form))
          sendTask({comIds:this.gsMainIds,taskName: "other-cj-jianzhang",cond}).then(res => {
            if(res.data.msg == 'success') {
              this.$qzfMessage('任务已发起',3)
              this.getList()
              this.$queueMessage(res.data.data.queue)

            }
          })
        }
      });
    },
    //申报
    handleUpdate(e) {
      this.$confirm('确定要检查吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {
          taskName: 'tax-jc-shenbao',
          comIds: [e.comId]
        }
        sendTask(param).then(res => {
          if(res.data.msg == 'success') {
            this.$qzfMessage('任务已发起',3)
            this.getList()
            this.$queueMessage(res.data.data.queue)

          }
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.top_btns {
  margin-bottom: 40px;
  .left_box{
    float: left;
  }
  .right_box {
    float: right;
    text-align: right;
  }
}
.filter-item1 {
  margin-right: 10px;
}
 
.el-table__column-filter-trigger .el-icon-arrow-down {
  color: white !important;
  width: 10px;
}
.none-border .div_p {
  line-height: 30px;
  border-bottom: 1px solid #eee;
  text-indent: 1em;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:last-child{
    border-bottom: none;
  }
}
.xgmStyle,.ybStyle {
  margin-left: 10px;
}
.item_icon{
  display: inline-block;
  cursor: pointer;
  i{
    display: inline-block;
    line-height: 28px;
    font-size: 16px;
  }
  .iconfont icon-cuowu{
    color: #f56c6c;
  }
  .iconfont icon-duihao{
    color: #67c23a;
  }
  .el-icon-circle-check{
    color: #67c23a;
  }
  .iconfont icon-info{
    color: #409eff
  }
  .iconfont icon-cuowu{
    color: #e6a23c;
  }
  .iconfont icon-cuowu-outline{
    color: #e6a23c;
  }

  p{
    display: inline-block;
    font-size: 14px;
    line-height: 28px;
    color: #333;
  }
}
.sbcg {
  padding: 0 !important;
}
.sbyc {
  color: red !important;
  padding: 0 !important;
}
</style>
<style lang="scss">
.el-table__column-filter-trigger i {
  color: black !important;
  width: 10px;
}
.none-border .cell {
  padding: 0;
}
.el-table th.el-table__cell>.cell.highlight {
    color: black !important;
}
</style>