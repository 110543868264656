import request from '@/utils/request'

//税控机柜
export function invoiceCollection(data) {
  return request({
    url: 'api/v2/collection/invoiceCollection',
    method: 'post',
    data
  })
}

//采集,申报,检查等
export function sendTask(data) {
  return request({
    url: 'api/v2/collection/SendTask',
    method: 'post',
    data
  })
}

//批量撤销
export function quitTask(data) {
  return request({
    url: 'api/v2/collection/quitTask',
    method: 'post',
    data
  })
}