import request from '@/utils/request'

// 建账采集历史数据
export function buildSubjectOldList(data) {
    return request({
        url: 'api/v2/build/buildSubjectOldList',
        method: 'post',
        data
    })
}
// 建账第一步
export function buildSubjectSaveOne(data) {
    return request({
        url: 'api/v2/build/buildSubjectSaveOne',
        method: 'post',
        data
    })
}

  // 建账第二步
export function buildSubjectSaveTwo(data) {
    return request({
        url: 'api/v2/build/buildSubjectSaveTwo',
        method: 'post',
        data
    })
}

// 建账第三步
export function buildSubjectSaveThree(data) {
    return request({
        url: 'api/v2/build/buildSubjectSaveThree',
        method: 'post',
        data
    })
}

// 建账第一步 list
export function buildSubjectSaveOneList(data) {
    return request({
        url: 'api/v2/build/buildSubjectSaveOneList',
        method: 'post',
        data
    })
}

// 移动科目
export function buildSubjectMove(data) {
    return request({
        url: 'api/v2/build/buildSubjectMove',
        method: 'post',
        data
    })
}

// 编辑科目
export function buildSubjectEdit(data) {
    return request({
        url: 'api/v2/build/buildSubjectEdit',
        method: 'post',
        data
    })
}

// 修改借贷方向
export function buildSubjectDirection(data) {
    return request({
        url: '/api/v2/build/buildSubjectDirection',
        method: 'post',
        data
    })
}

 //补全科目列表
export function getCompleteSubjects(data) {
    return request({
      url: '/api/v2/build/getCompleteSubjects',
      method: 'post',
      data
    })
}
  
//补全科目
export function completeSubject(data) {
    return request({
      url: '/api/v2/build/completeSubject',
      method: 'post',
      data
    })
}

// 建账状态修改
export function eaSubjectProcessSave(data) {
    return request({
        url: '/api/v2/build/saveEaSubjectBalanceInitProcess',
        method: 'post',
        data
    })
}

// 获取建账状态
export function eaSubjectProcessOne(data) {
    return request({
        url: '/api/v2/build/getEaSubjectBalanceInitProcess',
        method: 'post',
        data
    })
}

// 批量设为无需建账 默认建账
export function automaticAccountCreation(data) {
    return request({
        url: '/api/v2/build/automaticAccountCreation',
        method: 'post',
        data
    })
}

//重置
export function eaSubjectProcessDeleAll(data) {
    return request({
        url: '/api/v2/build/deleAll',
        method: 'post',
        data
    })
}
//批量补充
export function supplementInventory(data) {
    return request({
        url: '/api/v2/build/supplementInventory',
        method: 'post',
        data
    })
}



